.iminfo-page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.iminfo-main-content {
  display: flex;
  flex-direction: column;
  padding-left: 270px;
  padding-top: 70px;
  padding-right: 20px;
  padding-bottom: 60px;
  min-height: calc(100vh - 130px);
  box-sizing: border-box;
  margin: 20px;
  overflow-y: auto;
}

.iminfo-content-area {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 20px;
}

.iminfo-detail-container {
  position: relative; 
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  grid-column: 1 / span 2;
}

/* Make the invoice detail container position relative 
   so we can absolutely position the Edit button. */
  
  /* Top-right corner Edit button (similar to .settings-button in CaseDetail). */
  .iminfo-edit-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    cursor: pointer;
    font-size: 12px;
    color: #333;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border: 2px solid black;
    box-shadow: none;
    border-radius: 0;
  }
  
  /* Label styling (similar to .case-detail-label). */
  .iminfo-label {
    display: block;
    margin-bottom: 5px;
    color: #333;
    font-weight: bold;
  }
  
  /* Input styling (similar to .case-detail-input). */
  .iminfo-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px; /* Some spacing between fields */
  }
  
  /* Submit button styling (similar to .case-detail-edit-submit-btn). */
  .iminfo-edit-submit-btn {
    background-color: #f0512b;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px; /* Space above the button */
    transition: background-color 0.3s ease;
  }
  
  .iminfo-edit-submit-btn:hover {
    background-color: #e6451c;
  }
  

.iminfo-documents {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  grid-column: 3 / span 5;
  grid-row: 1 / span 2;  /* This will allow the documents div to grow and align */
  height: auto;
  overflow-y: auto;
}

/* Invoice Total container */
.iminfo-total {
  position: relative;       /* Allows absolute positioning inside */
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  grid-column: 1 / span 2;
  box-sizing: border-box;
  height: auto;
}

/* Absolutely positioned button in top-right corner of .iminfo-total */
.iminfo-total-edit-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  cursor: pointer;
  font-size: 12px;
  color: #333;
  padding: 5px 10px;
  border: 2px solid black;
  border-radius: 0;
  box-shadow: none;
  z-index: 2; /* ensure it's on top */
}

/* Add top margin to the .iminfo-h3 inside .iminfo-total so it's pushed below the button */
.iminfo-total .iminfo-h3 {
  margin-top: 40px; /* pushes heading down so it doesn't overlap the button */
}

/* Labels for the totals edit fields */
.iminfo-total-label {
  display: block;
  margin-bottom: 5px;
  color: #333;
  font-weight: bold;
}

/* Inputs for the totals edit fields */
.iminfo-total-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px; /* spacing between fields */
}

/* Submit button for totals editing */
.iminfo-total-edit-submit-btn {
  background-color: #f0512b;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.iminfo-total-edit-submit-btn:hover {
  background-color: #e6451c;
}



/* Invoice Display */
.iminfo-display {
  width: 100%; /* Ensures the div containing the table stretches */
  overflow-x: auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  grid-column: 1 / span 7;
  box-sizing: border-box;
  height: auto;
}

/* Styles for the header section of the invoice display */
.iminfo-display-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.iminfo-id-h3 {
  margin: 0;  /* Adjust margin as necessary */
}

/* Ensure the "Create Other Fee" and "Delete Files" buttons align properly */
.iminfo-display-buttons {
  display: flex;
  align-items: center;
  gap: 10px; /* Ensures equal spacing between buttons */
}

.iminfo-add-fee-btn {
  padding: 8px 16px;
  background-color: #f0512b; /* Green background for the button */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  max-width: 200px; /* Increase max width to accommodate longer text */
  white-space: nowrap; /* Prevents text from wrapping */
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: auto;
  margin-right: 10px; /* Keep spacing consistent */
}

.iminfo-add-fee-btn:hover {
  background-color: #e6451c; /* Darker shade of green on hover */
}

.iminfo-delete-combined-btn {
  background-color: #f0512b; /* Bright orange color */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: auto;
  margin-right: 10px; /* Spacing between the buttons */
}

.iminfo-delete-combined-btn:hover {
  background-color: #e6451c;
}

.iminfo-id-table {
  width: 100%;
  min-width: 100%; /* Ensures it spans the full available width */
  border-collapse: collapse;
  font-family: 'Arial', sans-serif;
  overflow-x: auto;
  display: table;
}

.iminfo-id-table thead {
  background-color: #333333; /* Dark grey background for header */
  color: #f2f2f2; /* Light grey/white text */
  font-weight: bold;
}

/* Make sure the table columns and cells do not wrap unnecessarily */
.iminfo-id-table th,
.iminfo-id-table td {
  padding: 12px 15px;
  border: 1px solid #bfbfbf;
  text-align: left;
  white-space: nowrap; /* Prevents wrapping in table cells */
}

.iminfo-id-table tbody tr:nth-child(even) {
  background-color: #f7f7f7; /* Very light grey background for even rows */
}

.iminfo-id-table tbody tr:nth-child(odd) {
  background-color: #ffffff; /* White background for odd rows */
}

.iminfo-id-table tbody tr:hover {
  background-color: #e6e6e6; /* Slight hover effect for rows */
}

.iminfo-id-table th {
  text-transform: none;  /* Changed from uppercase to default */
  letter-spacing: normal;  /* Adjusting to normal to reduce spacing */
  font-size: 0.9em;  /* Smaller font size for header */
}

.iminfo-id-table td {
  color: #333333; /* Dark grey text for body */
}

.iminfo-id-table tbody tr td:first-child {
  font-weight: bold;
}

.iminfo-id-h3 {
  color: #333333; /* Heading color to match the table theme */
  margin-bottom: 15px;
  font-size: 1.2em;
  border-bottom: 2px solid #bfbfbf; /* Professional border for the heading */
  padding-bottom: 5px;
}

.iminfo-h3 {
  background-color: #333333;
  color: #ffffff;
  padding: 10px 20px;
  margin-top: 0;
  border-radius: 5px;
  text-align: center;
}
/*
.iminfo-generate-documents-btn {
  background-color: #f0512b;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 40%; /* Adjust width as necessary * /
  max-width: 250px; / * Increase max width to accommodate longer text * /
  white-space: nowrap; / * Prevents text from wrapping * /
} 

.iminfo-generate-documents-btn:hover {
  background-color: #e6451c;
}
*/
/* Container for both buttons */
.iminfo-documents-buttons {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Spacing below the button row */
}

/* Use the same styling for both the generate and delete buttons */
.iminfo-generate-documents-btn,
.iminfo-delete-documents-btn {
  background-color: #f0512b; /* Bright orange color */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: auto;
  margin-right: 10px; /* Spacing between the buttons */
}

/* Hover effect for both buttons */
.iminfo-generate-documents-btn:hover,
.iminfo-delete-documents-btn:hover {
  background-color: #e6451c; /* Slightly darker shade of orange */
}

/* Optionally dim or style the "Delete Files" button when disabled */
.iminfo-delete-documents-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}


.iminfo-files-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.iminfo-files-table th,
.iminfo-files-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.iminfo-files-table th {
  background-color: #f2f2f2;
}

.iminfo-files-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.iminfo-files-table tr:hover {
  background-color: #f1f1f1;
}

.iminfo-files-table th {
  background-color: #333333;
  color: white;
}

.iminfo-files-table td a {
  color: #333333;
  text-decoration: none;
}

.iminfo-files-table td a:hover {
  text-decoration: underline;
}

.iminfo-success-message {
  color: #333333;
  font-weight: bold;
  margin-top: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .iminfo-content-area {
    grid-template-columns: 1fr;
  }

  .iminfo-detail-container,
  .iminfo-documents,
  .iminfo-features {
    grid-column: 1 / span 1;
  }

  .iminfo-display {
    grid-column: 1 / span 1;
  }

/*
  .iminfo-id-table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    width: max-content; /* Ensures the table expands beyond the parent container if needed * /
    min-width: 100%; / * Prevents unnecessary shrinking * /
  }
*/
  .iminfo-display {
    overflow-x: auto;
    display: block;
  }

  .iminfo-display-buttons {
    flex-direction: column;
    align-items: stretch; /* Make buttons full width on small screens */
  }

  .iminfo-add-fee-btn,
  .iminfo-delete-documents-btn {
    width: 100%; /* Full width on mobile */
    max-width: none; /* Remove any max-width restriction */
  }

}

