/* Accounting Landlord Fees Page Overall Layout */
.acc-land-page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .acc-land-main-content {
    display: flex;
    flex-direction: column;
    padding-left: 270px;
    padding-top: 70px;
    padding-right: 20px;
    padding-bottom: 60px;
    min-height: calc(100vh - 130px);
    box-sizing: border-box;
    margin: 20px;
    overflow-y: auto;
  }
  
  .acc-land-content-area {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 20px;
  }
  
  .acc-land-recent-fees {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    grid-column: 1 / span 7;
    grid-row: 1 / span 2;
    height: auto;
    overflow-y: auto;
  }
  
  /* Header with Search Bar and Delete Button */
  .acc-land-recent-fees-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .acc-land-search-bar {
    width: 250px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-right: 10px;
  }
  
  /* Fees Table Styles */
  .acc-land-fees-table {
    width: 100%;
    border-collapse: collapse;
    font-family: 'Arial', sans-serif;
  }
  
  .acc-land-fees-table th,
  .acc-land-fees-table td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .acc-land-fees-table th {
    background-color: #343a40;
    color: white;
  }
  
  .acc-land-fees-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .acc-land-fees-table tr:hover {
    background-color: #ddd;
  }

  /* Other Fees Section */
  .acc-land-other-fees {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    grid-column: 1 / span 7;
    grid-row: 3 / span 2;
    height: auto;
    overflow-y: auto;
  }

  .acc-land-other-fees-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .acc-land-other-search-bar {
    width: 250px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-right: 10px;
  }

  .acc-land-other-fees-table {
    width: 100%;
    border-collapse: collapse;
    font-family: 'Arial', sans-serif;
  }

  .acc-land-other-fees-table th,
  .acc-land-other-fees-table td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: left;
  }

  .acc-land-other-fees-table th {
    background-color: #343a40;
    color: white;
  }

  .acc-land-other-fees-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .acc-land-other-fees-table tr:hover {
    background-color: #ddd;
  }

  .acc-land-other-delete-btn {
    margin-left: 10px;
    padding: 8px 12px;
    background-color: #ff4d4f;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .acc-land-other-delete-btn:hover {
    background-color: #ff7875;
  }

  /* Export to Excel Button */
  .acc-land-export-btn {
    background-color: #28a745; /* Green color similar to Excel */
    color: white;
    font-weight: bold;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px; /* Spacing between text and icon */
    transition: background-color 0.3s ease, transform 0.2s ease;
  }

  .acc-land-export-btn:hover {
    background-color: #218838; /* Darker green on hover */
    transform: scale(1.05); /* Slight zoom effect */
  }

  /* Optional: Adding an Excel-like icon */
  .acc-land-export-btn::before {
    content: "📊"; /* Excel-like icon */
    font-size: 16px;
  }


  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .acc-land-content-area {
      grid-template-columns: 1fr;
    }
  
    .acc-land-recent-fees {
      grid-column: 1 / span 1;
    }
  }
  
  /* Style for the delete button */
  .acc-land-delete-btn {
    margin-left: 10px;
    padding: 8px 12px;
    background-color: #ff4d4f;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .acc-land-delete-btn:hover {
    background-color: #ff7875;
  }
  
  /* Adjust table styles if necessary */
  .acc-land-fees-table th,
  .acc-land-fees-table td {
    padding: 12px;
    text-align: left;
  }
  
  .acc-land-fees-table th input[type='checkbox'],
  .acc-land-fees-table td input[type='checkbox'] {
    margin: 0;
  }

  .acc-land-content-area {
    grid-template-columns: 1fr;
  }

  .acc-land-recent-fees,
  .acc-land-other-fees { /* Apply to both sections */
    grid-column: 1 / span 1;
  }

  /* Style for the delete button */
  .acc-land-delete-btn,
  .acc-land-other-delete-btn { /* Apply to both delete buttons */
    margin-left: 10px;
    padding: 8px 12px;
    background-color: #ff4d4f;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .acc-land-delete-btn:hover,
  .acc-land-other-delete-btn:hover {
    background-color: #ff7875;
  }

  /* Adjust table styles if necessary */
  .acc-land-fees-table th,
  .acc-land-fees-table td,
  .acc-land-other-fees-table th,
  .acc-land-other-fees-table td { /* Apply to both tables */
    padding: 12px;
    text-align: left;
  }

  .acc-land-fees-table th input[type='checkbox'],
  .acc-land-fees-table td input[type='checkbox'],
  .acc-land-other-fees-table th input[type='checkbox'],
  .acc-land-other-fees-table td input[type='checkbox'] { /* Apply to both tables */
    margin: 0;
  }
