/* Page Layout */
.ru-page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
  
.ru-main-content {
    display: flex;
    flex-direction: column;
    padding-left: 270px;
    padding-top: 70px;
    padding-right: 20px;
    padding-bottom: 60px;
    min-height: calc(100vh - 130px);
    box-sizing: border-box;
    margin: 20px;
    overflow-y: auto;
}

/* Two-column layout */
.ru-two-columns {
    display: flex;
    gap: 20px;
}

/* Styling for Both Columns */
.ru-content-area {
    flex: 1;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 100%;
}

/* Existing Users Styling */
.ru-existingusers {
    width: 100%;
    background: #3a404d;
    border-radius: 10px;
    box-shadow: 0 7px 30px rgba(62, 9, 11, 0.3);
    padding: 15px;
    color: white;
    max-width: 400px;
    margin: auto;
}

/* NEW: a header container for "Existing Users" + Delete btn in one row */
.ru-users-header {
    display: flex;
    align-items: center;
    justify-content: space-between; /* heading on left, button on right */
    margin-bottom: 10px;            /* spacing below the header row */
}

.ru-users-header h3 {
    margin: 0;       /* remove default margins for a tidier look */
    font-size: 18px;
    color: #e1e1e1;
    /* no text-align: center if you want it left-aligned */
}

/* The existing <ol> / <li> styles remain mostly the same */
.ru-existingusers ol {
    counter-reset: leaderboard;
    padding: 0;
    margin: 0;
    list-style: none;
}

.ru-existingusers li {
    display: flex;
    align-items: center;
    font-size: 14px;
    counter-increment: leaderboard;
    padding: 15px 10px;
    cursor: pointer;
    background: #faa48e;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    transition: 0.3s;
}

.ru-existingusers li:hover {
    background: #f39264;
}

.ru-existingusers li mark {
    background: none;
    color: white;
    font-weight: bold;
    display: inline-block;
    margin-left: 5px; /* small gap from checkbox area, optional */
}

/* Make the 'role' appear in its own column to the far right */
.ru-existingusers li small {
    margin-left: auto; /* pushes 'small' to the far right */
    float: none;       /* remove any float */
    font-size: 13px;
    font-weight: bold;
    color: white;
}

/* The user checkbox area */
.ru-user-checkbox {
    margin-right: 15px;
    transform: scale(1.2);
    display: flex;
    align-items: center;
    justify-content: center;
}

/* The circular user ID bubble */
.ru-user-id {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    line-height: 30px;
    color: #c24448;
    background: #fff;
    border-radius: 50%;
    text-align: center;
    font-weight: bold;
    margin-right: 15px;
}

/* Registration Form Styles */
.ru-register-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    margin: auto;
}
  
.ru-input-group {
    display: flex;
    align-items: center;
    background: #f4f4f4;
    border-radius: 5px;
    padding: 10px;
}
  
.ru-input-group input,
.ru-input-group select {
    border: none;
    background: none;
    flex: 1;
    padding: 8px;
    font-size: 16px;
    outline: none;
}
  
.ru-icon {
    color: #888;
    margin-right: 10px;
}
  
.ru-register-btn {
    background: #6cb2eb;
    color: white;
    font-weight: bold;
    padding: 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
  
.ru-register-btn:hover {
    background: #4a90e2;
}
  
.ru-error {
    color: red;
    text-align: center;
    font-size: 14px;
    margin-bottom: 10px;
}

/* Action Buttons (for Delete) - we position it in .ru-users-header now */
.ru-delete-btn {
    background: #d9534f;
    color: white;
    font-size: 14px;
    font-weight: bold;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.ru-delete-btn:hover {
    background: #c9302c;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .ru-two-columns {
        flex-direction: column;
    }
}
